var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isAllCompleted
    ? _c("section", { staticClass: "section" }, [
        _c("div", { staticClass: "container container--fullWidth" }, [
          _c("div", { staticClass: "tasks-container" }, [
            _c("div", { staticClass: "progress-bar" }, [
              _c("div", {
                staticClass: "progress-bar--completed",
                style: "width:" + _vm.taskProgressBarWidth + "%",
              }),
            ]),
            _c(
              "ul",
              { staticClass: "task-list" },
              _vm._l(_vm.tasks, function (task, index) {
                return _c(
                  "router-link",
                  {
                    key: index,
                    staticClass: "task",
                    class: { done: task.isDone },
                    attrs: { to: { path: task.fullPath } },
                  },
                  [
                    _c("li", [
                      _c("p", { staticClass: "task--number" }, [
                        _c("span", { staticClass: "textSizeSm" }, [
                          _vm._v(_vm._s(index + 1)),
                        ]),
                      ]),
                      _c("p", { staticClass: "task--description" }, [
                        _vm._v(_vm._s(task.description)),
                      ]),
                    ]),
                  ]
                )
              }),
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }