var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-producer" },
    [
      _vm._m(0),
      _c("TasksProgress"),
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "container container--fullWidth grid grid__col6" },
          [
            _c("TileAnalytics", {
              attrs: {
                title: "Earnings",
                data: _vm.totalSales ? _vm.totalSales.last_month : 0,
                icon: "money-bag",
                color: _vm.analyticsColors.earnings.hex,
              },
            }),
            _c("TileAnalytics", {
              attrs: {
                title: "Beats Sold",
                data: _vm.beatSold ? _vm.beatSold.last_month : 0,
                icon: "cart",
                color: _vm.analyticsColors.beatSold.hex,
              },
            }),
            _c("TileAnalytics", {
              attrs: {
                title: "Demo DLs",
                data: _vm.beatDemoDownloads
                  ? _vm.beatDemoDownloads.last_month
                  : 0,
                icon: "cloud-dl",
                color: _vm.analyticsColors.demoDownloads.hex,
              },
            }),
            _c("TileAnalytics", {
              attrs: {
                title: "Beat Plays",
                showDateRange: false,
                data: _vm.beatPlays ? _vm.beatPlays.all_time : 0,
                icon: "play",
                color: _vm.analyticsColors.beatPlays.hex,
              },
            }),
            _c("TileAnalytics", {
              attrs: {
                title: "Page Views",
                showDateRange: false,
                data: _vm.pageVisits ? _vm.pageVisits.all_time : 0,
                icon: "eye",
                color: _vm.analyticsColors.pageViews.hex,
              },
            }),
            _c("TileAnalytics", {
              attrs: {
                title: "Followers",
                showDateRange: false,
                data: _vm.authenticatedUser
                  ? _vm.authenticatedUser.number_of_followers || 0
                  : 0,
                icon: "followers",
                color: _vm.analyticsColors.followers.hex,
              },
            }),
          ],
          1
        ),
      ]),
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "container container--fullWidth wrapper--filled" },
          [
            _vm.isLoading || !_vm.detailedSales || !_vm.detailedBeatSold
              ? _c("VLoadSpinner")
              : [
                  _c("div", { staticClass: "flexbox h-mb-30" }, [
                    _c("h2", [_vm._v("Earnings")]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.closeMenuDropdown,
                            expression: "closeMenuDropdown",
                          },
                        ],
                        staticClass: "dropdown",
                        staticStyle: { width: "200px", "margin-left": "auto" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "formInput formInput--fullWidth formInput--selectCustom",
                            on: { click: _vm.toggleMenuDropdown },
                          },
                          [
                            _vm._v(_vm._s(_vm.selectedPeriod.showText)),
                            _c("icon", { attrs: { name: "arrow-down" } }),
                          ],
                          1
                        ),
                        _vm.menuDropDown
                          ? _c("ul", { staticClass: "dropdown__menu" }, [
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "dropdown__item dropdown__item--sm",
                                  class: {
                                    isActive:
                                      _vm.selectedPeriod.id ===
                                      _vm.periods[0].id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setPeriod(0)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.periods[0].showText))]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "dropdown__item dropdown__item--sm",
                                  class: {
                                    isActive:
                                      _vm.selectedPeriod.id ===
                                      _vm.periods[1].id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setPeriod(1)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.periods[1].showText))]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _c("SaleEarnings", {
                    attrs: {
                      height: 150,
                      width: 450,
                      detailedSales: _vm.detailedSales,
                      detailedBeatSold: _vm.detailedBeatSold,
                      isMonthly: _vm.selectedPeriod.id === _vm.periods[1].id,
                    },
                  }),
                ],
          ],
          2
        ),
      ]),
      _vm.detailedBeatPlays && _vm.topTenBeats
        ? _c("section", { staticClass: "section" }, [
            _c(
              "div",
              { staticClass: "container container--fullWidth grid grid__col2" },
              [
                _c(
                  "div",
                  { staticClass: "wrapper--filled" },
                  [
                    _vm._m(1),
                    _c("BeatPlays", {
                      attrs: {
                        height: 100,
                        width: 100,
                        chartPoints: _vm.detailedBeatPlays,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wrapper--filled" },
                  [
                    _vm._m(2),
                    _c("Top10Beats", {
                      attrs: {
                        height: 100,
                        width: 100,
                        chartPoints: _vm.topTenBeats,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm.detailedBeatDemoDownloads && _vm.detailedPageVisits
        ? _c("section", { staticClass: "section" }, [
            _c(
              "div",
              { staticClass: "container container--fullWidth grid grid__col2" },
              [
                _c(
                  "div",
                  { staticClass: "wrapper--filled" },
                  [
                    _vm._m(3),
                    _c("BeatDownloads", {
                      attrs: {
                        height: 100,
                        width: 100,
                        chartPoints:
                          _vm.detailedBeatDemoDownloads.by_days.last_30_days,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wrapper--filled" },
                  [
                    _vm._m(4),
                    _c("PageViews", {
                      attrs: {
                        height: 100,
                        width: 100,
                        chartPoints: _vm.detailedPageVisits,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h3", [_vm._v("Dashboard")]),
          _c("h1", [_vm._v("Overview")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "h-mb-30" }, [
      _vm._v("Beats Plays"),
      _c("span", { staticClass: "textSizeSm" }, [_vm._v(" (last 7 days)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "h-mb-30" }, [
      _vm._v("Top 10 Beats"),
      _c("span", { staticClass: "textSizeSm" }, [_vm._v(" (last 7 days)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "h-mb-30" }, [
      _vm._v("Demo Downloads"),
      _c("span", { staticClass: "textSizeSm" }, [_vm._v(" (last 30 days)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "h-mb-30" }, [
      _vm._v("Page Views"),
      _c("span", { staticClass: "textSizeSm" }, [_vm._v(" (last 7 days)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }