var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "analytics-tile" },
    [
      _c("icon", {
        staticClass: "analytics-tile__icon",
        attrs: { name: _vm.icon, color: _vm.color },
      }),
      _c("div", [
        _c("div", { staticClass: "analytics-tile__title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm.title === "Earnings"
          ? _c(
              "div",
              {
                staticClass: "analytics-tile__value",
                style: { color: _vm.color },
              },
              [_vm._v("$" + _vm._s(_vm._f("twoDecimals")(_vm.data)))]
            )
          : _c(
              "div",
              {
                staticClass: "analytics-tile__value",
                style: { color: _vm.color },
              },
              [_vm._v(_vm._s(_vm.data))]
            ),
        _c("div", { staticClass: "analytics-tile__date-range" }, [
          _vm._v(_vm._s(_vm.showDateRange ? _vm.daysRange : "ALL TIME")),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }